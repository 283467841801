body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footerText {
  margin-top: 20px !important;
  font-weight: 600;
  font-size: x-small;
  color: white;
  text-decoration: none;
}

.footerTextLower {
  margin-top: -10px !important;
  font-weight: 100;
  font-size: 10px;
}

.logosmall {
  width: 60px;
  height: 60px;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 15px;
}
